<template>
  <section class="bg-card text-card-foreground py-24">
    <!-- Feature 1 -->
    <div class="max-w-7xl mx-8 sm:mx-auto">
      <!-- Section header -->
      <div class="text-center">
        <h1 class="text-3xl font-bold lg:text-5xl">
          {{ t("marketing.features.feature1.title") }}
        </h1>
      </div>

      <!-- 3 card for display our solution -->
      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-12 mt-20 sm:mx-12"
      >
        <div
          v-for="card in cardData"
          :key="card.title"
          class="rounded-2xl p-6 border border-1 flex flex-col items-center text-center transition-all hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 duration-700"
        >
          <!-- Flex container to make sure content is stretched -->
          <div class="flex flex-col items-center text-center flex-grow">
            <!-- Fix image size and center it -->
            <img
              :src="card.image"
              class="w-32 h-32 object-cover"
              alt="Feature"
            />

            <!-- Fixed height container to ensure titles have the same height -->
            <div class="flex flex-col items-center">
              <h3 class="text-2xl font-bold mt-4 text-primary text-left">
                {{ t(card.title) }}
              </h3>
            </div>
          </div>

          <div class="flex flex-col items-center text-start flex-grow mt-4">
            <p class="mt-2 text-base leading-normal opacity-70">
              {{ t(card.description) }}
            </p>
          </div>

          <!-- Adjust Button alignment and spacing -->
          <NuxtLinkLocale
            :to="card.link"
            class="px-3 py-2 text-lg text-foreground hover:text-foreground/80 custom-hover-effect font-semibold whitespace-nowrap mt-4"
          >
            {{ t("marketing.features.learnMore") }}
          </NuxtLinkLocale>
        </div>
      </div>

      <hr class="my-20 border-primary/10 dark:border-primary/20" />
      <!-- container for partner logos -->
      <div class="grid grid-cols-3 gap-16 mt-20 lg:grid-cols-6 mx-8">
        <div
          v-for="partner in partnerData"
          :key="partner.alt"
          class="flex justify-center items-center"
        >
          <img :src="partner.image" :alt="partner.alt" class="h-12" />
        </div>
      </div>
    </div>

    <!-- Essayez notre solution et constatez la différence ! -->
    <div class="mt-20 bg-blue-50 py-20">
      <div class="max-w-7xl mx-auto">
        <CardImage
          :image="{
            src: 'https://res.cloudinary.com/ds9ewjix6/image/upload/v1715682341/computer_sufutz.png',
            alt: 'tech-startup-image',
          }"
          position="right"
          mobile-position="top"
        >
          <div class="flex flex-col sm:mt-24 ml-4">
            <div class="w-full text-left">
              <span class="text-3xl font-bold lg:text-4xl text-black">
                {{ t("marketing.features.feature2.title") }}
              </span>
            </div>
            <div
              class="w-4/5 mt-12 text-left mx-auto xl:mx-0 text-gray-400 sm:text-gray-600 font-medium"
            >
              <span>
                {{ t("marketing.features.feature2.description1") }}
              </span>
              <br />
              <br />
              <span>
                {{ t("marketing.features.feature2.description2") }}
              </span>
              <br />
              <br />
              <span>
                {{ t("marketing.features.feature2.description3") }}
              </span>
            </div>
          </div>
          <div
            class="flex self-center sm:justify-center xl:justify-end mt-8 w-4/5"
          >
            <Button
              class="mt-8 text-xl h-14 font-light"
              variant="outline"
              size="lg"
            >
              <Icon name="qrCode" class="text-primary h-6 w-6 sm:mr-4" />
              {{ t("marketing.features.functionality") }}
            </Button>
          </div>
        </CardImage>
      </div>
    </div>

    <!-- A QUI S'ADRESS PROSPERE.IO? -->
    <div class="mt-20 max-w-7xl mx-8 sm:mx-auto">
      <div class="text-center">
        <h1 class="text-3xl font-bold lg:text-5xl">
          {{ t("marketing.features.feature3.title") }}
        </h1>
      </div>

      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-12 mt-20 sm:mx-12"
      >
        <div
          v-for="client in clientData"
          :key="client.title"
          class="rounded-2xl border border-1 flex flex-col transition-all hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 duration-700"
        >
          <!-- Image container with fixed height -->
          <div class="flex-shrink-0 w-full overflow-hidden rounded-t-2xl">
            <img
              :src="client.image"
              :alt="client.alt"
              class="w-full h-full object-cover"
            />
          </div>

          <hr class="w-2/3 h-4 bg-primary mx-auto -mb-4 -mt-2" />
          <!-- Content container -->
          <div class="flex flex-col items-center text-center p-6 flex-grow">
            <h3 class="text-2xl font-extrabold mt-4 text-primary">
              {{ t(client.title) }}
            </h3>
            <p
              class="mt-4 sm:text-sm text-xl font-extrabold leading-normal opacity-70 text-left whitespace-pre-line"
            >
              {{ t(client.description) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Votre outil au quotidien -->
    <div class="mt-20 xl:mt-40 bg-blue-50 py-20 lg:pl-12">
      <div class="max-w-7xl mx-auto">
        <CardImage
          :image="{
            src: 'https://res.cloudinary.com/ds9ewjix6/image/upload/v1715697057/computer2_elgum0.png',
            alt: 'tech-startup-image',
          }"
          position="left"
          mobile-position="top"
        >
          <div class="flex flex-col sm:mt-20">
            <div class="w-full text-center lg:text-left">
              <span class="text-3xl font-bold lg:text-4xl text-black">
                {{ t("marketing.features.feature4.title") }}
              </span>
            </div>
            <div
              class="w-4/5 mt-12 text-left mx-auto xl:mx-0 text-gray-400 sm:text-gray-600 font-medium"
            >
              <span>
                {{ t("marketing.features.feature4.description1") }}
              </span>
              <br />
              <br />
              <span>
                {{ t("marketing.features.feature4.description2") }}
              </span>
              <br />
              <br />
              <span>
                {{ t("marketing.features.feature4.description3") }}
              </span>
            </div>
          </div>
          <div
            class="flex self-center lg:self-start sm:justify-center xl:justify-start mt-8 w-4/5"
          >
            <Button
              class="mt-8 text-xl h-14 font-light"
              variant="outline"
              size="lg"
            >
              <Icon name="qrCode" class="text-primary h-6 w-6 sm:mr-4" />
              {{ t("marketing.features.functionality") }}
            </Button>
          </div>
        </CardImage>
      </div>
    </div>

    <!-- LES PROGRAMMES IMMOBILIERS À LA UNE -->
    <div class="mt-32 mx-10 max-w-7xl sm:mx-auto">
      <div class="text-center">
        <h1 class="text-3xl font-extrabold lg:text-4xl">
          {{ t("marketing.features.feature5.title") }}
        </h1>
      </div>

      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-12 mt-20 sm:mx-12"
      >
        <div v-for="home in homeData" :key="home.title" class="flex flex-col">
          <!-- Content container -->
          <div class="flex flex-col items-start text-left flex-grow">
            <h3 class="text-xl font-bold">
              {{ home.title }}
            </h3>
            <p
              class="mb-4 text-base leading-normal opacity-70 text-left whitespace-pre-line font-medium"
            >
              {{ home.subtitle }}
            </p>
          </div>
          <div
            class="flex-shrink-0 w-full overflow-hidden rounded-xl transition-all hover:shadow-lg hover:-translate-y-1 hover:-translate-x-1 duration-700"
          >
            <img
              :src="home.image"
              :alt="home.alt"
              class="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- La première solution de vente d’immobilier neuf utilisant l’Ia -->
    <div class="mt-20 bg-blue-50 py-20">
      <div class="max-w-7xl sm:mx-auto mx-8">
        <CardImage
          :image="{
            src: 'https://res.cloudinary.com/ds9ewjix6/image/upload/v1715768698/b130027f-8893-497e-a9a8-abdcf089a1eb.png',
            alt: 'AI-image',
            class: 'max-w-xs w-full rounded-xl',
          }"
          position="right"
          mobile-position="top"
        >
          <div class="flex flex-col sm:ml-12 mb-12 sm:mb-0">
            <div class="text-left">
              <span class="text-xl sm:text-3xl font-bold text-black">
                {{ t("marketing.features.feature6.title") }}
              </span>
            </div>
            <div
              class="mt-12 text-left mx-auto xl:mx-0 text-gray-400 sm:text-gray-600 font-medium text-sm sm:text-lg"
            >
              <span>
                {{ t("marketing.features.feature6.description1") }}
              </span>
              <br />
              <br />
              <span>
                {{ t("marketing.features.feature6.description2") }}
              </span>
              <br />
              <br />
              <span>
                {{ t("marketing.features.feature6.description3") }}
              </span>
            </div>
          </div>
        </CardImage>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const { t } = useTranslations();
const cardData = [
  {
    title: "marketing.features.feature1.benefit1.title",
    description: "marketing.features.feature1.benefit1.description",
    image:
      "https://res.cloudinary.com/ds9ewjix6/image/upload/v1715675783/63c733de-ee62-4867-a550-7f445363b3c2.png",
    link: "/",
  },
  {
    title: "marketing.features.feature1.benefit2.title",
    description: "marketing.features.feature1.benefit2.description",
    image:
      "https://res.cloudinary.com/ds9ewjix6/image/upload/v1715883999/think_iqtgo6.png",
    link: "/",
  },
  {
    title: "marketing.features.feature1.benefit3.title",
    description: "marketing.features.feature1.benefit3.description",
    image:
      "https://res.cloudinary.com/ds9ewjix6/image/upload/v1715675799/53e54e33-fe8a-4c9d-9af8-6c74f13456b3.png",
    link: "/",
  },
];
const partnerData = [
  {
    image:
      "https://res.cloudinary.com/ds9ewjix6/image/upload/v1716192958/LOGOS/rigaoymjmdxnemtdffle.png",
    alt: "Vinci Immobilier",
  },
  {
    image:
      "https://res.cloudinary.com/ds9ewjix6/image/upload/v1716192957/LOGOS/fjtkbkskuogicy1gr9n5.svg",
    alt: "Fondation Nexity",
  },
  {
    image:
      "https://res.cloudinary.com/ds9ewjix6/image/upload/v1716192956/LOGOS/vfmkb5jrkpo2zaoyhgqq.png",
    alt: "COGEDIM",
  },
  {
    image:
      "https://res.cloudinary.com/ds9ewjix6/image/upload/v1716192956/LOGOS/cdsrelimcf1flhmxfeex.svg",
    alt: "CA Immobilier",
  },
  {
    image:
      "https://res.cloudinary.com/ds9ewjix6/image/upload/v1716213355/LOGOS/tcxmitllkanqmeour5ku.png",
    alt: "BNB PARIBAS IMMOBILIER",
  },
  {
    image:
      "https://res.cloudinary.com/ds9ewjix6/image/upload/v1716196392/LOGOS/lkc1bl7ldyzsciidrnje.svg",
    alt: "ICADE",
  },
];
const clientData = [
  {
    title: "marketing.features.feature3.benefit1.title",
    description: "marketing.features.feature3.benefit1.description",
    image:
      "https://res.cloudinary.com/ds9ewjix6/image/upload/v1715695572/189a51f4-e6ac-4c50-8523-95510ab1e16c.png",
    alt: "Prescripteurs",
  },
  {
    title: "marketing.features.feature3.benefit2.title",
    description: "marketing.features.feature3.benefit2.description",
    image:
      "https://res.cloudinary.com/ds9ewjix6/image/upload/v1715695594/7a234032-3db6-495c-a42a-2b8b74fe72a8.png",
    alt: "Banquiers",
  },
  {
    title: "marketing.features.feature3.benefit3.title",
    description: "marketing.features.feature3.benefit3.description",
    image:
      "https://res.cloudinary.com/ds9ewjix6/image/upload/v1715695716/b01b2e18-a3f9-455e-9357-e1b4321c1238.png",
    alt: "Agents immobiliers",
  },
  {
    title: "marketing.features.feature3.benefit4.title",
    description: "marketing.features.feature3.benefit4.description",
    image:
      "https://res.cloudinary.com/ds9ewjix6/image/upload/v1715695605/b6356de4-cc9e-4550-9b5c-3016a298d1ba.png",
    alt: "Courtiers",
  },
];
const homeData = [
  {
    title: "Résidence La lune",
    subtitle: "Paris 75017",
    image:
      "https://res.cloudinary.com/ds9ewjix6/image/upload/v1715714209/36421bf6-9aaa-40fb-8111-49724507c9a0.png",
    alt: "Résidence La lune",
  },
  {
    title: "Résidence La lune à Perpignan",
    subtitle: "",
    image:
      "https://res.cloudinary.com/ds9ewjix6/image/upload/v1715714253/bbf7ac58-c2a5-44d2-a2fb-689465649de7.png",
    alt: "Résidence La lune à Perpignan",
  },
  {
    title: "Résidence La lune à Perpignan",
    subtitle: "",
    image:
      "https://res.cloudinary.com/ds9ewjix6/image/upload/v1715714253/bbf7ac58-c2a5-44d2-a2fb-689465649de7.png",
    alt: "Résidence La lune à Perpignan",
  },
  {
    title: "Résidence La lune à Perpignan",
    subtitle: "",
    image:
      "https://res.cloudinary.com/ds9ewjix6/image/upload/v1715714280/23fa883e-d6c4-4164-bc42-a55884181fc6.png",
    alt: "Résidence La lune à Perpignan",
  },
];
</script>
