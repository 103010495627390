<template>
  <Disclaimer />
  <!-- <MarketingHero /> -->
  <MarketingFeatures />
  <MarketingNewsletter />
</template>

<script setup lang="ts">
definePageMeta({
  layout: "marketing",
});
useHead({
  title: "Home",
  meta: [
    {
      name: "description",
      content: "Prospere is a SaaS platform for real estate professionals.",
    },
  ],
});
</script>
