<template>
  <div class="max-w-7xl mx-8 sm:mx-auto mb-20">
    <div class="mb-12 text-center">
      <h1 class="text-3xl font-bold lg:text-4xl text-primary">
        {{ t("newsletter.title") }}
      </h1>
      <p class="mt-3 text-lg opacity-70 whitespace-pre-line">
        {{ t("newsletter.subtitle") }}
      </p>
    </div>

    <div class="flex justify-center">
      <Button
        size="lg"
        class="whitespace-nowrap items-center self-center text-lg h-12 mb-4 lg:mb-0 font-bold"
      >
        {{ t("newsletter.demo") }}
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
const { apiCaller } = useApiCaller();
const { t } = useTranslations();

const { z, toTypedSchema, useForm } = formUtils;

const formSchema = toTypedSchema(
  z.object({
    email: z.string().email(t("newsletter.hints.error.input")),
  })
);

const isSubmitSuccessful = ref(false);

const { defineInputBinds, handleSubmit, setFieldError } = useForm({
  validationSchema: formSchema,
  initialValues: {
    email: "",
  },
});

const _email = defineInputBinds("email");

const _onSubmit = handleSubmit(
  async (values) => {
    try {
      const emailSent = await apiCaller.newsletter.signup.mutate({
        email: values.email,
      });
      if (!emailSent) {
        throw new Error("Email not sent");
      }

      isSubmitSuccessful.value = true;
    } catch (_e) {
      isSubmitSuccessful.value = false;
      setFieldError("email", t("newsletter.hints.error.submission"));
    }
  },
  () => {
    isSubmitSuccessful.value = false;
  }
);
</script>
