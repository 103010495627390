<template>
  <div class="mx-auto xl:px-20 lg:px-4 max-w-7xl">
    <div class="flex justify-center mb-12 w-full -mt-10 lg:mt-20">
      <div class="flex flex-col lg:flex-row justify-center mx-4 sm:mx-20">
        <div
          class="flex justify-center mx-auto py-5 items-center lg:w-6/12 xl:w-5/12 order-2"
        >
          <img
            src="https://res.cloudinary.com/ds9ewjix6/image/upload/v1715848080/home_zffnyw.png"
            alt="tech-startup-image"
            class="max-w-xs w-full h-auto"
            style="max-width: 100%"
          />
        </div>

        <div
          class="flex flex-col mx-auto text-center justify-center lg:w-7/12 xl:w-7/12 sm:mx-6 md:mx-8 lg:mx-0 order-1"
        >
          <div class="flex flex-col w-full text-left mx-auto">
            <div class="w-4/5 lg:w-full mx-auto lg:-ml-12">
              <span class="text-2xl font-extrabold 2xl:text-4xl">
                {{ t("marketing.home.title_1") }}
                <span class="text-primary">
                  {{ t("marketing.home.title_2") }}
                </span>
                {{ t("marketing.home.title_3") }}
                <br />
                {{ t("marketing.home.title_4") }}
                <span class="text-primary">
                  {{ t("marketing.home.title_5") }}
                </span>
              </span>
            </div>
            <div class="w-4/5 lg:w-3/5 mt-4 sm:mt-20 mx-auto lg:mx-0">
              <span class="text-lg lg:text-lg">
                {{ t("marketing.home.subtitle") }}
              </span>
            </div>
          </div>
          <div class="flex justify-center lg:justify-start mt-10">
            <div class="flex flex-col sm:flex-row">
              <div class="sm:mr-5 mb-4 sm:mb-0">
                <NuxtLinkLocale to="/auth/signup">
                  <Button
                    size="lg"
                    variant="outline"
                    class="text-2xl px-10 h-12 w-60"
                  >
                    {{ t("marketing.home.inscription") }}
                  </Button>
                </NuxtLinkLocale>
              </div>
              <div>
                <Button
                  size="lg"
                  class="whitespace-nowrap w-full text-lg px-10 h-12 mb-4 lg:mb-0"
                >
                  {{ t("marketing.home.demo") }}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useTranslations();
</script>

<style scoped></style>
